/*  Colors  */

$green: #85CC03;
$dark-green: #3f8a04;
$orange: #FB9706;
$dark-orange: #b85900;
$black: #000;

/*  Fonts  */

$font: 'Roboto Flex';

/*  Dimensions  */

$tablet: 1024px;
$tabletS: 768px;
$phone: 512px;

/*  Default Reset  */

*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body, #root {
  height: 100%;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}

a {
  text-decoration: none;
}